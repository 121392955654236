import React from 'react'
// import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Container from '../components/Container'
import PageBody from '../components/PageBody'
import ScrollingText from '../components/ScrollingText'
import styled from '@emotion/styled'
import ImageGallery from '../components/ImageGallery'
import Quote from '../components/Quote'
import Results from '../components/Results'
import Download from '../components/Download'
import TextEffect from '../components/riseEffect/TextEffect'
import Media from '../components/Media'

const PostSections = ({ post }) => {
  const sections = post.sections
  // FIRST - HERO
  const HeroImageSection = section => {
    return (
      <Container>
        {section?.hero?.heroVideo?.file?.url ? (
          <Media
            videoCheck={section.hero.heroVideo.file.url?.slice(-3)}
            videoSrcURL={section.hero.heroVideo.file.url}
            fluid={section.hero.heroVideo.fluid || null}
            alt={section.hero.heroVideo.title || 'Hero Video'}
            title={section.hero.heroVideo.title || 'No Title'}
            description={section.hero.heroVideo.description || ''}
          />
        ) : section?.hero?.heroImage?.fluid ? (
          <Img
            fluid={section.hero.heroImage.fluid}
            alt={section.hero.heroImage.title || 'Hero Image'}
          />
        ) : (
          <p>No Hero Media Available</p>
        )}
      </Container>
    )
  }

  // // SECOND - TITLE
  const TitleSection = section => {
    const TitleContainer = styled.section`
      margin: 40px 0;
      border-top: 1px solid white;
      border-bottom: 1px solid white;
    `

    return (
      <TitleContainer>
        <ScrollingText text={section?.title?.title || 'No Title Available'} />
      </TitleContainer>
    )
  }

  // THIRD - ABSTRACT
  const AbstractSection = section => {
    const TextContainer = styled.section`
      padding: 40px 40px 80px 40px;
      display: flex;
      justify-content: space-between;

      .leftText {
        display: flex;
      }

      .abstract {
        width: 350px;
        font-size: 1.6rem;

        p {
          line-height: 1.3;
        }
      }

      .paragraph {
        width: 59%;
        margin-left: 100px;
        font-weight: 300;

        p {
          line-height: 1.6;
        }
      }

      .date {
        p {
          font-size: 0.8rem;
          font-weight: 700;
        }

        span {
          display: block;
        }
      }

      @media (max-width: 1050px) {
        .leftText {
          width: 100%;
          flex-direction: column;
        }

        .abstract {
          width: 570px;
          margin-bottom: 30px;
        }

        .paragraph {
          width: 570px;
          margin-left: 0;
        }
      }

      @media (max-width: 840px) {
        .abstract {
          width: 85%;
        }

        .paragraph {
          width: 85%;
        }
      }

      @media (max-width: 700px) {
        flex-direction: column-reverse;

        .date {
          margin-bottom: 15px;

          span {
            display: inline-block;
          }
        }
      }

      @media (max-width: 550px) {
        .abstract {
          width: 100%;
        }

        .paragraph {
          width: 100%;
        }
      }
    `

    return (
      <TextContainer>
        <div className="leftText">
          <div className="abstract">
            <TextEffect
              text={
                section?.abstract?.abstract?.childMarkdownRemark?.html ||
                '<p>No abstract available</p>'
              }
            />
          </div>

          <div className="paragraph">
            <TextEffect
              text={
                section?.abstract?.paragraph?.childMarkdownRemark?.html ||
                '<p>No paragraph available</p>'
              }
            />
          </div>
        </div>
        <div className="date">
          <p>
            {post?.publishDate
              ? post.publishDate.substr(0, 3).toUpperCase()
              : new Date()
                  .toLocaleString('default', { month: 'short' })
                  .toUpperCase()}
            &nbsp;
            <span>
              {post?.publishDateISO
                ? post.publishDateISO.substr(0, 4)
                : new Date().getFullYear()}
            </span>
          </p>
        </div>
      </TextContainer>
    )
  }

  // // FIFTH - QUOTE
  const QuoteSection = section => {
    const quoteHtml =
      section?.quote?.quote?.childMarkdownRemark?.html ||
      '<p>No quote available</p>'

    return (
      <Quote
        quote={quoteHtml}
        quotePerson={section?.quote?.quotePerson || 'Unknown'}
        quoteTitle={section?.quote?.quoteTitle || 'No Title'}
      />
    )
  }

  // // SEVENTH - BODY
  const BodySection = section => {
    return (
      <PageBody
        body={section?.body || {}}
        bodyTitle={section?.body?.bodyTitle || 'Title not available'}
      />
    )
  }

  // // SIXTH - RESULTS
  const ResultsSection = section => {
    const results = section?.results || {}

    return (
      <Results
        metric1Result={results.metric1Result || 'N/A'}
        metric1Title={results.metric1Title || 'Title not available'}
        metric2Result={results.metric2Result || 'N/A'}
        metric2Title={results.metric2Title || 'Title not available'}
        metric3Result={results.metric3Result || 'N/A'}
        metric3Title={results.metric3Title || 'Title not available'}
      />
    )
  }

  // Função para renderizar a seção apropriada com base no __typename
  const renderSection = section => {
    switch (section.__typename) {
      case 'ContentfulPostHero':
        return <HeroImageSection hero={section} key={section.id} />
      case 'ContentfulPostTitle':
        return <TitleSection title={section} key={section.id} />
      case 'ContentfulPostAbstract':
        return <AbstractSection abstract={section} key={section.id} />
      case 'ContentfulPostImageGallery':
        return <ImageGallery images={section.imageGallery} key={section.id} />
      // <GallerySection gallery={section}/>
      case 'ContentfulPostQuote':
        return <QuoteSection quote={section} key={section.id} />
      case 'ContentfulPostBody':
        return <BodySection body={section} key={section.id} />
      case 'ContentfulPostResults':
        return <ResultsSection results={section} key={section.id} />
      case 'ContentfulPostDownload': // Corrigido para maiúsculas
        return <Download section={section.download} key={section.id} />

      default:
        return null
    }
  }

  return (
    <div>
      {sections && Array.isArray(sections) ? (
        sections.map(section => renderSection(section))
      ) : (
        <p>No sections available</p>
      )}
    </div>
  )
}

export default PostSections
