import React from 'react'
import { graphql } from 'gatsby'
import PostSections from '../components/PostSections'

const PostTemplate = ({ data }) => {
  const post = data.contentfulPost

  return <PostSections post={post} />
}

export const query = graphql`
  query($slug: String!) {
    contentfulPost(slug: { eq: $slug }) {
      title
      slug
      metaDescription {
        internal {
          content
        }
      }
      publishDate(formatString: "MMMM DD, YYYY")
      publishDateISO: publishDate(formatString: "YYYY-MM-DD")
      tags {
        title
        id
        slug
      }
      sections {
        __typename
        ... on ContentfulPostTitle {
          id
          title
        }
        ... on ContentfulPostHero {
          id
          heroImage {
            title
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_withWebp
            }
            ogimg: resize(width: 1800) {
              src
              width
              height
            }
          }
          heroVideo {
            file {
              url
            }
            description
            title
          }
          hoverMedia {
            file {
              url
            }
            description
            title
          }
          hoverDescription {
            childMarkdownRemark {
              timeToRead
              html
            }
          }
        }
        ... on ContentfulPostQuote {
          id
          quoteTitle
          quotePerson
          quote {
            quote
            childMarkdownRemark {
              html
            }
          }
        }
        ... on ContentfulPostImageGallery {
          id
          title
          imageGallery {
            file {
              fileName
              url
            }
            fluid(maxWidth: 1000) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
        ... on ContentfulPostBody {
          id
          bodyTitle
          bodyParagraph {
            bodyParagraph
            childMarkdownRemark {
              timeToRead
              html
              excerpt(pruneLength: 320)
            }
          }
        }
        ... on ContentfulPostAbstract {
          id
          abstract {
            childMarkdownRemark {
              timeToRead
              html
              excerpt(pruneLength: 320)
            }
          }
          paragraph {
            childMarkdownRemark {
              timeToRead
              html
              excerpt(pruneLength: 320)
            }
          }
        }
        ... on ContentfulPostResults {
          id
          metric1Result
          metric1Title
          metric2Result
          metric2Title
          metric3Result
          metric3Title
        }
        ... on ContentfulPostDownload {
          id
          title
          download {
            file {
              url
              fileName
            }
          }
        }
      }
    }
  }
`
export default PostTemplate
